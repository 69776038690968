import { render, staticRenderFns } from "./StagelineEndPage.vue?vue&type=template&id=60960c46"
import script from "./StagelineEndPage.vue?vue&type=script&lang=js"
export * from "./StagelineEndPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('60960c46')) {
      api.createRecord('60960c46', component.options)
    } else {
      api.reload('60960c46', component.options)
    }
    module.hot.accept("./StagelineEndPage.vue?vue&type=template&id=60960c46", function () {
      api.rerender('60960c46', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/StagelineV2/StagelineEndPage.vue"
export default component.exports