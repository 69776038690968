var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sl_standard_page" },
    [
      _vm.loaded
        ? [
            _vm._t("body", function () {
              return [
                _vm.isDomainOnlyCompany
                  ? _c("domains-first-end-slide", {
                      attrs: { "is-slide": _vm.isSlide },
                    })
                  : _c("standard-end-slide", {
                      attrs: { "is-slide": _vm.isSlide },
                    }),
              ]
            }),
          ]
        : _c("ct-centered-spinner"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }