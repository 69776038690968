<template>
  <div class="sl_standard_page">
    <template v-if="loaded">
      <slot name="body">
        <domains-first-end-slide
          v-if="isDomainOnlyCompany"
          :is-slide="isSlide"
        />
        <standard-end-slide
          v-else
          :is-slide="isSlide"
        />
      </slot>
    </template>

    <ct-centered-spinner v-else />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'StagelineEndPage',
  components: {
    CtCenteredSpinner:    () => import('@/components/shared/CtCenteredSpinner'),
    DomainsFirstEndSlide: () => import('@/components/StagelineV2/stagelineEnd/DomainsFirstEndSlide.vue'),
    StandardEndSlide:     () => import('@/components/StagelineV2/stagelineEnd/StandardEndSlide.vue'),
  },
  props: {
    isSlide: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapGetters('stageline', ['isDomainOnlyCompany', 'company']),
  },
  async mounted() {
    const params      = this.$route.params
    const companyId   = params.companyId

    if (!this.company && companyId) {
      await this.setCurrentCompany({ id: companyId })
    }
    this.loaded = true
  },
  methods: {
    ...mapActions('companies', [
      'setCurrentCompany',
    ]),
  },
}
</script>
